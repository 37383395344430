import React from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons";

const imageUrl = process.env.PUBLIC_URL + '/images/ATS_Insubria.png';
const Header = () => {
    // Implementa qui la logica per creare una prenotazione
    return (
        <>
            <div className="headr-top">
                <div className="container d-flex flex-row align-items-center gap-5">
                    <a href="https://www.ats-insubria.it/">
                        <img src={imageUrl} alt="Logo ATS Insubria" style={{height: '100px'}}/>
                    </a>
                    <div>
                        <h2 className="text-primary fw-bold fs-5">ATS Insubria</h2>
                        <div className="text-primary">CUP per prenotazione prestazioni</div>
                    </div>
                </div>
            </div>
            <Navbar bg="primary" data-bs-theme="dark">
                <Container>
                    <Nav className="me-auto w-100">
                        <div className="d-flex flex-row justify-content-between w-100">
                            <div className="d-flex flex-row">
                                <Nav.Link href="https://www.ats-insubria.it/" className="fw-bold text-white text-uppercase">Home</Nav.Link>
                                <Nav.Link href="/contatti" className="fw-bold text-white text-uppercase">Contatti</Nav.Link>
                            </div>
                            <div>
                                <Nav.Link href="/admin/" className="fw-bold text-white text-uppercase">
                                    <FontAwesomeIcon icon={faRightFromBracket}/>
                                    <span className="ms-2">Accesso Personale ATS</span>
                                </Nav.Link>
                            </div>
                        </div>


                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;
