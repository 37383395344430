import React from 'react';

const RelatedNotes = () => {

        return (
            <div className="container">
                <h1>Note Legali</h1>

                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris nec libero tincidunt, elementum ipsum id, mattis nibh. Etiam egestas, elit vitae iaculis elementum, diam massa aliquet est, ac aliquet nisi massa et lorem. Vivamus fringilla elit eu lorem molestie, in semper justo tincidunt. Vestibulum tortor leo, elementum eget eleifend ut, semper nec massa. Nam dapibus viverra ultricies. Donec ac risus sed tellus lacinia ornare. Ut convallis fermentum orci vel finibus. Suspendisse potenti. Maecenas nunc quam, egestas a luctus quis, imperdiet eu ex. Vivamus sed augue massa. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed non mollis sapien. Aliquam volutpat rhoncus nisl. Vestibulum eget vehicula erat, id elementum turpis. Vivamus ullamcorper magna ut consequat posuere. Proin iaculis enim id commodo faucibus.

                    Duis vitae mattis eros, ac volutpat elit. Suspendisse volutpat lorem dolor, vitae scelerisque lorem tincidunt id. In urna ipsum, aliquam vel risus in, dictum tincidunt velit. Nullam porttitor, felis in luctus ullamcorper, turpis ligula suscipit turpis, ut vestibulum arcu ante eget sem. Maecenas bibendum quam ut augue pretium, eget mattis neque mollis. Sed eu odio eget nisi laoreet ornare congue id erat. Praesent viverra sodales diam, non dignissim justo. Aliquam ut elit metus. Morbi at imperdiet lectus, id sollicitudin neque. In ac leo nunc. Cras vitae convallis nibh. Donec vulputate ex eu feugiat aliquam. Praesent luctus viverra lorem, tincidunt egestas nunc porta ac. Duis vitae nisl quis lacus convallis sodales. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                </p>
            </div>
        );
};

export default RelatedNotes;
