import React, {useContext, useEffect, useState} from 'react';
import {Button, Spinner, Table, Tabs} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import SliderComponent from "../components/SliderComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faClock} from "@fortawesome/free-regular-svg-icons";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
    cleanJSON,
    formatDate, formatDateTime,
    formatMinutesToTime,
    formatTime,
    getFollowingDays,
    getSlot
} from "../assets/utilities/utilities";
import {addMinutes, format} from "date-fns";
import {ToastContext} from "../services/ToastService";
import UserTimeline from "../components/UserTimeline";
import ModalSummary from "../components/ModalSummary";
import {Logs, Service, User} from "../assets/interface/interface";
import ModalNewService from "../components/ModalNewService";
import {useFormik} from "formik";
import * as Yup from "yup";
import AvatarUser from "../components/AvatarUser";
import {Status} from "../assets/utilities/enum";
import {config} from "../config";
import {
    useCreateAssignBookingMutation,
    useCreateDistrictServiceMutation,
    useCreateManualServiceMutation,
    useGetComuniQuery, useGetDistrictCalendarQuery,
    useGetEventsToAssignQuery, useGetLogsQuery, useGetNearestVeterinaryQuery,
    useGetPrestazioniQuery,
    useGetSessionQuery
} from "../services/apiSlice";
import {useNavigate} from "react-router-dom";

const FarmAnimalActivities = () => {

    const [districtId, setDistrictId] = useState<number>();
    const [eventKey, setEventKey] = useState("service_to_assign");

    const { data: sessionData, error, isLoading } = useGetSessionQuery();
    const { data: prestazioniData, error: prestazioniError } = useGetPrestazioniQuery(true);
    const {
        data: comuniData,
        error: errorComuni,
        refetch: refetchComuniData} = useGetComuniQuery(districtId, {
        skip: !districtId
    });

    const {
        data: servicesToAssignData,
        error: errorServicesToAssign,
        isLoading: isLoadingServicesToAssign,
        refetch: refetchServicesToAssignData } = useGetEventsToAssignQuery(districtId, {
            skip: !districtId
        });

    const [slot, setSlot] = useState<number>(60);
    const {
        data: districtCalendarData,
        error: errorDistrictCalendarData,
        isLoading: isLoadingDistrictCalendarData,
        refetch: refetchDistrictCalendarData,
    } = useGetDistrictCalendarQuery({
            district_id: districtId,      // Valore dinamico per il distretto
            slot_duration: slot,  // Valore dinamico per la durata dello slot
            end_date: '2024-10-31',       // Valore fisso o dinamico per la data di fine
        }, {
            skip: !districtId
        }
    );

    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && sessionData) {
            setDistrictId(sessionData.district_id);
            // Se l'utente non è admin, reindirizza
            if (!sessionData.logged ||( sessionData.role !== 'veterinario' && sessionData.role !== 'admin')) {
                window.location.href = `${config.apiUrl}/admin/`;
            }
        }
    }, [sessionData, isLoading, navigate]);

    const [dateSelected, setDateSelected] = useState<string>();
    const [serviceSelected, setServiceSelected] = useState<Service>();
    const [userSelected, setUserSelected] = useState<User>();

    const {
        data: nearestVeterinaryData,
        error: errorNearestVeterinaryData,
        isLoading: isLoadingNearestVeterinaryData,
        refetch: refetchNearestVeterinaryData,
    } = useGetNearestVeterinaryQuery({
        district_id: sessionData ? sessionData.district_id : null, // Assicurati che district_id sia null se non disponibile
        start_time: dateSelected ? formatDateTime(dateSelected) : "", // Funzione per formattare la data
        end_time: dateSelected ? formatDateTime(addMinutes(new Date(dateSelected), slot)) : "", // Formatta l'oggetto Date in yyyy-mm-dd
        booking_id: serviceSelected ? serviceSelected.id : 0,
    }, {
        skip: !dateSelected || !serviceSelected
    });

    const { data: logsData, error: errorLogs, refetch: refetchLogsData} = useGetLogsQuery(districtId,
    {
        skip: !districtId
    });

    const [positionUserToShow, setPositionUserToShow] = useState<number>(0);

    useEffect(() => {
        if (districtId) {
            refetchServicesToAssignData();
            refetchComuniData();
            refetchDistrictCalendarData();
            refetchLogsData();
        }
    }, [districtId]);

    useEffect(() => {
        if (districtCalendarData) {
            const newEvents = districtCalendarData.map((evt) => ({
                start: evt.booking_date_time_start,
                end: evt.booking_date_time_end,
                display: 'background',
            }));

            setEvents(newEvents);
        }
    }, [districtCalendarData]);

    useEffect(() => {


        if (dateSelected) {
            refetchNearestVeterinaryData();
            // resetSlickTransform('.veterinari-container .slick-track');
            // setPositionUserToShow(0);
        }
    }, [dateSelected]);

    useEffect(() => {
        // resetSlickTransform('.veterinari-container .slick-track');
        // setPositionUserToShow(0);
    }, [nearestVeterinaryData]);

    const [activeCardIndex, setActiveCardIndex] = useState<number | null>();

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [businessHours, setBusinessHours] = useState<any[]>();

    const [events, setEvents] = useState<any[]>([]);

    const [smallestStartTime, setSmallestStartTime] = useState<string>();
    const [largestEndTime, setLargestEndTime] = useState<string>();

    const [startDate, setStartDate] = useState<any>(getFollowingDays([0, 6], 0));

    const validRange = {start: startDate};

    const allDay = [0, 1, 2, 3, 4, 5, 6,];

    const {showToast} = useContext(ToastContext)!;

    const [location, setLocation] = useState("");
    const [modalSummaryShow, setModalSummaryShow] = useState(false);
    const [modalNewServiceShow, setModalNewServiceShow] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const handleDateSelect = (arg: any) => {
        setDateSelected(arg.start);
    };

    useEffect(() => {

        if (!servicesToAssignData || servicesToAssignData.length === 0){
            return;
        }

        handlePrestazioneSelect(0);
        // resetSlickTransform('.services-container .slick-track');

    }, [servicesToAssignData]);

    const handlePrestazioneSelect = (index: number) => {

        if (activeCardIndex == index) {
            return;
        }

        if (dateSelected && eventKey === "service_to_assign") {
            refetchNearestVeterinaryData();
            // resetSlickTransform('.veterinari-container .slick-track');
            // setPositionUserToShow(0);
        }

        setActiveCardIndex(index);
        if (!servicesToAssignData || servicesToAssignData.length === 0){
            return;
        }

        const newSlot = prestazioniData && prestazioniData.filter(x => x.id == servicesToAssignData[index].tipo_prestazione)[0]?.slot;
        if (newSlot) {
            setSlot(newSlot);
        }

        const comune = comuniData && comuniData.filter(x => x.id == servicesToAssignData[index].comune)[0]?.descrizione;
        if (comune) {
            setLocation(comune);
        }
        if (servicesToAssignData && servicesToAssignData[index]){
            setServiceSelected(servicesToAssignData[index]);
        } else {
            console.warn("Prestazione non trovata");
        }

        refetchDistrictCalendarData();
    };

    function confirmAppointment(user: User) {
        console.log('serviceSelected', serviceSelected);
        setUserSelected(user);
        setModalSummaryShow(true);
    }

    const [createDistrictService, { isLoading: isLoadingCreateDistrictService, isSuccess: isSuccessDistrictService,  error: errorDistrictService }] = useCreateDistrictServiceMutation();
    const [createAssignBooking, { isLoading: isLoadingCreateAssignBooking, isSuccess: isSuccessCreateAssignBooking,error: errorCreateAssignBooking }] = useCreateAssignBookingMutation();

    const formikNewService = useFormik({
        initialValues: {
            tipo_prestazione: null,
            comune: "",
            note: ""
        },
        validationSchema: Yup.object({
            tipo_prestazione: Yup.mixed().required(""),
            comune: Yup.mixed().required(""),
            note: Yup.string(),
        }),
        onSubmit: (values) => {
            createService(cleanJSON(values));

        }
    });

    const createService = async (values: any) => {

        try {
            await createDistrictService(values).unwrap();  // unwrap per gestire l'errore
            showToast("success", "Successo", "Prestazione creata con successo.");

            setModalNewServiceShow(false);
            await refetchServicesToAssignData();

            setDateSelected("");
            setUserSelected(undefined);
            setServiceSelected(undefined);
            setActiveCardIndex(null);

        } catch (error) {
            console.error('Errore nella creazione del post:', error);
        }
    }

    const assignBooking = async () => {
        try {
            if (!serviceSelected?.id || !userSelected?.id || !dateSelected) {
                throw new Error('Missing required data: service, user, or date.');
            }

            const localStartDate = new Date(dateSelected);
            const localStartDateFormatted = formatDateTime(new Date(localStartDate));

            const localEndDate = addMinutes(new Date(dateSelected), slot);
            const localEndDateFormatted = formatDateTime(new Date(localEndDate));

            const values = {
                booking_id: serviceSelected.id,
                user_id: userSelected.id,
                booking_date_time_start: localStartDateFormatted,
                booking_date_time_end: localEndDateFormatted,
            };

            await createAssignBooking(values).unwrap();
            setModalSummaryShow(false);
            showToast("success", "Successo", "Prenotazione assegnata con successo.");
            refetchServicesToAssignData();
            refetchLogsData();
            setDateSelected("");
            setUserSelected(undefined);
            setServiceSelected(undefined);
            setActiveCardIndex(null);
        } catch (error) {
            console.error('Error assigning booking:', error);
        }
    };


    function showServiceModal(service: Logs) {

        if (service.stato === "to_be_assigned"){
            return;
        }

        const showService: any = {
            ...service, // Spread all existing properties from the original service
            tipo_prestazione: service.id_prestazione, // Map the id_prestazione to tipo_prestazione
            comune: service.id_comune,               // Map the id_comune to comune
        };

        setServiceSelected(showService);

        if (service.booking_date_time_start){
            setDateSelected(service.booking_date_time_start);
        }
        const user = {
            id: service.user_id,
            name: service.name,
            surname: service.surname,
            appointments: service.appointments
        }

        if (user){
            setUserSelected(user);
        }
        setModalSummaryShow(true);
    }

    function selectTab(k: any) {
        if (k === eventKey) {
            return;
        }
        setEventKey(k);

        setDateSelected("");
    }

    function goToCalendarPets() {
        window.location.href = `${config.apiUrl}/admin/calendar.php`;
    }

    return (
            <div className="container">{!isLoading ?
                <div>
                    <div className="d-flex flex-column flex-md-row justify-content-between gap-0 gap-md-5">
                        <div>
                            <h1>Attività animali da reddito</h1>
                            <p>Scegli la tua prestazione e compila tutti i campi per la creazione della prenotazione del tuo appuntamento. Se hai bisogno di assistenza, contatta lo sportello selezionato. Forniamo un servizio clienti dedicato per rendere il processo di prenotazione semplice ed efficiente.</p>
                        </div>
                        <div style={{marginTop: "50px", whiteSpace: "nowrap"}}>
                            <Button className="bg-white" variant="outline-primary fw-bold"
                                onClick={goToCalendarPets}>Calendario Attività per animali d’affezione</Button>
                        </div>
                    </div>

                    <Tabs
                        activeKey={eventKey}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 mt-5"
                        onSelect={(k: any) => selectTab(k)}
                    >
                        <Tab eventKey="service_to_assign" title="Attività da assegnare">
                            <div className="mt-4">
                                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2 gap-md-3 mb-5 mb-md-0">
                                    <div className="d-flex align-items-center gap-3">
                                        <h2 className="fw-bold mb-0">Seleziona attività</h2>
                                        {isLoadingServicesToAssign && <Spinner animation="border" role="status" variant="primary">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>}
                                    </div>
                                    <div style={{whiteSpace: "nowrap"}}>
                                        <Button className="bg-white" variant="outline-primary fw-bold" onClick={() => setModalNewServiceShow(true)}>Inserimento attività manuale</Button>
                                    </div>
                                </div>

                                <div className="my-4 services-container" aria-hidden="true">

                                    {servicesToAssignData && servicesToAssignData.length == 0 && <div>Non ci sono prestazioni da assegnare</div>}

                                    {servicesToAssignData && servicesToAssignData.length > 0 && <div className={servicesToAssignData.length < 3 ? "min-3-element" : ""}>

                                        <SliderComponent slideShow={3}>

                                            {servicesToAssignData.map((service, index) => (
                                                <div key={index}>
                                                    <div
                                                        className={`card-container ${activeCardIndex === index ? 'active' : ''}`}
                                                        title={prestazioniData && prestazioniData.filter(x => x.id == service.tipo_prestazione)[0]?.descrizione}
                                                        onClick={() => handlePrestazioneSelect(index)}
                                                    >
                                                        <h2 className="fw-bold mb-3">{prestazioniData && prestazioniData.filter(x => x.id == service.tipo_prestazione)[0]?.descrizione}</h2>

                                                        <div className="d-flex flex-row gap-3 align-items-center my-2">
                                                            <div className="fs-5 text-primary">
                                                                <FontAwesomeIcon icon={faLocationDot}/>
                                                            </div>
                                                            <div>{comuniData && comuniData.filter(x => x.id == service.comune)[0].descrizione}</div>
                                                        </div>

                                                        <div className="d-flex flex-row gap-3 align-items-center">
                                                            <div className="fs-5 text-primary">
                                                                <FontAwesomeIcon icon={faClock}/>
                                                            </div>
                                                            <div>{prestazioniData && formatTime(prestazioniData.filter(x => x.id == service.tipo_prestazione)[0]?.slot)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </SliderComponent>
                                    </div>}
                                </div>

                                <div className="row">

                                    <div className="mb-100 col-sm-9">

                                        <div className="d-flex align-items-center gap-3">
                                            <h2 className="my-3 fw-bold">
                                                Calendario
                                            </h2>

                                            {isLoadingDistrictCalendarData && <Spinner animation="border" role="status" variant="primary">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>}
                                        </div>
                                        
                                        <div className={`p-4 bg-light-grey ${isLoadingDistrictCalendarData || !serviceSelected ? 'disabled-calendar' : ''}`}>
                                            <FullCalendar
                                                plugins={[timeGridPlugin, interactionPlugin]}
                                                initialView={isMobile ? "timeGridDay" : "timeGridWeek"}
                                                slotLabelInterval="01:00"
                                                slotMinTime={smallestStartTime ? smallestStartTime : "07:00:00"}
                                                slotMaxTime={largestEndTime ? largestEndTime : "20:00:00"}
                                                selectable={true}
                                                slotDuration={slot ? formatMinutesToTime(slot) : "01:00:00"}
                                                select={handleDateSelect}
                                                expandRows={true}
                                                initialDate={dateSelected}
                                                validRange={validRange}
                                                weekends={true}
                                                locale="it"
                                                handleWindowResize={true}
                                                height="600px"
                                                allDaySlot={false}
                                                events={events}
                                                slotEventOverlap={false}
                                                eventOverlap={false}
                                                businessHours={businessHours}
                                                headerToolbar={{
                                                    left: 'title',
                                                    right: 'prev,next',
                                                }}
                                                selectOverlap={false}
                                                // selectConstraint={businessHours ? "businessHours" : "lesson-unavailable"}
                                                selectLongPressDelay={0}
                                                longPressDelay={0}
                                            />

                                            {dateSelected && <>
                                                <h2 className="my-3 pt-4">
                                                    Prenotazione Selezionata:
                                                </h2>

                                                <div className="d-flex flex-column flex-md-row gap-3">
                                                    <div className="bg-secondary p-4 border-1 border-primary flex-fill" style={{borderStyle: 'solid'}}>
                                                        <div className="d-flex flex-row gap-3 align-items-center">
                                                            <div className="fs-3 text-primary">
                                                                <FontAwesomeIcon icon={faCalendar}/>
                                                            </div>
                                                            <div className="fw-bold text-uppercase text-black">
                                                                { format(new Date(dateSelected), 'dd/MM/yyyy') }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="bg-secondary p-4 border-1 border-primary flex-fill" style={{borderStyle: 'solid'}}>
                                                        <div className="d-flex flex-row gap-3 align-items-center">
                                                            <div className="fs-3 text-primary">
                                                                <FontAwesomeIcon icon={faClock}/>
                                                            </div>
                                                            <div className="fw-bold text-uppercase text-black">
                                                                { new Date(dateSelected).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                                                                - { addMinutes(new Date(dateSelected), (slot ? slot : 60)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>}
                                        </div>


                                    </div>

                                    <div className="mb-100 col-sm-3 mt-5 mt-md-0 veterinari-container">

                                        <div className="d-flex align-items-center gap-3">
                                            <h2 className="my-3 fw-bold">
                                                Veterinari
                                            </h2>
                                            {isLoadingNearestVeterinaryData && <Spinner animation="border" role="status" variant="primary">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>}
                                        </div>


                                        {dateSelected && nearestVeterinaryData && nearestVeterinaryData.length > 0 && <SliderComponent slideShow={1}>

                                            {nearestVeterinaryData.map((user, index) => (
                                                <div key={index}>
                                                    <div className="p-4 bg-light-grey">
                                                        {serviceSelected &&  <UserTimeline user={user}
                                                                       selectedDate={new Date(dateSelected)}
                                                                       slot={slot ? slot : 60}
                                                                       comuneId={serviceSelected.comune}
                                                                       confirmAppointment={confirmAppointment}
                                                        ></UserTimeline>}
                                                    </div>
                                                </div>
                                            ))}

                                        </SliderComponent>}

                                        {!dateSelected && <div className="p-4 bg-light-grey">
                                            Seleziona una data per visualizzare un veterinario disponibile
                                        </div>}
                                    </div>

                                </div>
                            </div>

                            {districtId && <ModalNewService modalShow={modalNewServiceShow}
                                              setModalShow={setModalNewServiceShow}
                                              formik={formikNewService}
                                              districtId={districtId}
                                            isLoading={isLoadingCreateDistrictService}
                            ></ModalNewService>}

                        </Tab>
                        <Tab eventKey="all_service" title="Attività">

                            <Table responsive className="table-design mt-2">
                                <thead>
                                <tr>
                                    <th>Prestazione</th>
                                    <th>Data</th>
                                    <th>Comune</th>
                                    <th>Veterinario</th>
                                    <th>Stato</th>
                                </tr>
                                </thead>
                                <tbody>

                                {logsData && logsData.length === 0 && <div>Non ci sono attività</div>}

                                {logsData && logsData.map((service, index) => (
                                    <tr key={index} onClick={() => showServiceModal(service)}>
                                        <td className="fw-bold" title={service.descrizione}>
                                            <div className="table-prestazione">
                                                {service.descrizione}
                                            </div>

                                        </td>
                                        <td>
                                            {service.booking_date_time_start ? new Date(service.booking_date_time_start).toLocaleDateString('it-IT', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                            }) : "-"}
                                        </td>
                                        <td>{service.comune}</td>
                                        <td>
                                            {service.name ? <AvatarUser user={{name: service.name, surname: service.surname}}></AvatarUser> : "-"}
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                                <div className={`status ${service.stato}`}></div>
                                                <div className="text-uppercase">{Status && service.stato && Status[service.stato]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>

                    {userSelected && dateSelected && serviceSelected && <ModalSummary slot={slot} isLoading={isLoadingCreateAssignBooking} modalShow={modalSummaryShow} setModalShow={setModalSummaryShow} userSelected={userSelected}
                                                                              dateSelected={new Date(dateSelected)} serviceSelected={serviceSelected} assignBooking={assignBooking}></ModalSummary>}

                </div> :
                <Spinner animation="border" role="status" variant="primary" className="mt-5">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}

            </div>
        );
};

export default FarmAnimalActivities;
