import React from "react";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import FormText from "./FormText";
import FormUpload from "./FormUpload";

interface Props {
    formik: any;
    tipologia: string;
    goToBackTab(): void;
}

const OwnershipData = (props: Props) => {
    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    const cancel = () => {
        props.goToBackTab();
    };

    return (
        <Formik initialValues={props.formik.initialValues}  onSubmit={props.formik.handleSubmit}>

            <Form onSubmit={props.formik.handleSubmit}>
                <div className="p-4 rounded-lg mb-100">
                    <h2 className="my-3">
                        Dati Personali
                    </h2>

                    <div className="row">
                        <div className="col-md-6 my-3">
                            <FormText name="nome"  value={props.formik.nome} onChange={props.formik.handleChange} error={props.formik.errors["nome"]}
                                      type="text" label="Nome" placeholder="Inserisci qui"></FormText>

                        </div>
                        <div className="col-md-6 my-3">
                            <FormText name="cognome" value={props.formik.cognome} onChange={props.formik.handleChange} error={props.formik.errors["cognome"]}
                                      type="text" label="Cognome" placeholder="Inserisci qui"></FormText>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 my-3">
                            <FormText name="codicefiscale"  value={props.formik.codicefiscale} onChange={props.formik.handleChange} error={props.formik.errors["codicefiscale"]}
                                      type="text" label="Codice Fiscale" placeholder="Inserisci qui"></FormText>
                        </div>
                        <div className="col-md-6 my-3">
                            <FormText name="indirizzo"  value={props.formik.indirizzo} onChange={props.formik.handleChange} error={props.formik.errors["indirizzo"]}
                                      type="text" label="Indirizzo" placeholder="Inserisci qui"></FormText>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 my-3">
                            <FormText name="citta"  value={props.formik.citta} onChange={props.formik.handleChange} error={props.formik.errors["citta"]}
                                      type="text" label="Città" placeholder="Inserisci qui"></FormText>
                        </div>
                        <div className="col-md-6 my-3">
                            <FormText name="cap"  value={props.formik.cap} onChange={props.formik.handleChange} error={props.formik.errors["cap"]}
                                      type="number" label="CAP" placeholder="Inserisci qui"></FormText>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 my-3">
                            <FormText name="provincia"  value={props.formik.provincia} onChange={props.formik.handleChange} error={props.formik.errors["provincia"]}
                                      type="text" label="Provincia" placeholder="Inserisci qui"></FormText>
                        </div>
                        <div className="col-md-6 my-3">
                            <FormText name="telefono"  value={props.formik.telefono} onChange={props.formik.handleChange} error={props.formik.errors["telefono"]}
                                      type="number" label="Telefono" placeholder="Inserisci qui"></FormText>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 my-3">
                            <FormText name="email"  value={props.formik.email} onChange={props.formik.handleChange} error={props.formik.errors["email"]}
                                      type="text" label="Email" placeholder="Inserisci qui"></FormText>
                        </div>
                        <div className="col-md-6 my-3">
                            <FormUpload formik={props.formik} name="documento_carta_identita"  value={props.formik.values.documento_carta_identita} error={props.formik.errors["documento_carta_identita"]}
                                        type="file" label="Documento Carta di identità (1)"></FormUpload>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 my-3">
                            <FormUpload formik={props.formik} name="documento_codicefiscale"  value={props.formik.values.documento_codicefiscale} error={props.formik.errors["documento_codicefiscale"]}
                                        type="file" label="Documento Codice Fiscale (1)"></FormUpload>
                        </div>

                         <div className="col-md-6 my-3">
                            <FormText  name="animali_per_prenotazione"  value={props.formik.values.animali_per_prenotazione} onChange={props.formik.handleChange} error={props.formik.errors["animali_per_prenotazione"]}
                                       type="number" label="Numero animali per prenotazione" placeholder="Inserisci qui"></FormText>
                        </div>

                    </div>





















                    {/*{props.tipologia === "2" && <>*/}

                    {/*    <hr />*/}

                    {/*    <div className="row">*/}
                    {/*        <div className="col-md-6 my-3 mt-3">*/}
                    {/*            <FormSwitch*/}
                    {/*                name="dati_cedente"*/}
                    {/*                value={props.formik.values.dati_cedente}*/}
                    {/*                onChange={props.formik.handleChange}*/}
                    {/*                id="dati_cedente"*/}
                    {/*                label="Dati cedente"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    {props.formik.values.dati_cedente && <>*/}
                    {/*        <h2 className="my-3 mt-5">*/}
                    {/*            Dati Cedente*/}
                    {/*        </h2>*/}

                    {/*        <div className="row">*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormText name="dati_cedente_nome"  value={props.formik.dati_cedente_nome} onChange={props.formik.handleChange}*/}
                    {/*                          type="text" label="Nome" placeholder="Inserisci qui"></FormText>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormText name="dati_cedente_cognome"  value={props.formik.dati_cedente_cognome} onChange={props.formik.handleChange}*/}
                    {/*                          type="text" label="Cognome" placeholder="Inserisci qui"></FormText>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="row">*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormText name="dati_cedente_luogo_di_nascita"  value={props.formik.dati_cedente_luogo_di_nascita} onChange={props.formik.handleChange}*/}
                    {/*                          type="text" label="Nato a" placeholder="Inserisci qui"></FormText>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormDatePicker  name="dati_cedente_data_di_nascita" value={props.formik.dati_cedente_data_di_nascita}*/}
                    {/*                                 label="Nato il" formik={props.formik}></FormDatePicker>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="row">*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormSelect name="dati_cedente_sesso" formik={props.formik} label="Sesso" value={props.formik.values.dati_cedente_sesso}*/}
                    {/*                            options={[{id: "M", descrizione: "M"}, {id: "F", descrizione: "F"}]}></FormSelect>*/}

                    {/*            </div>*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormText name="dati_cedente_codicefiscale"  value={props.formik.dati_cedente_codicefiscale} onChange={props.formik.handleChange}*/}
                    {/*                          type="text" label="Codice Fiscale" placeholder="Inserisci qui"></FormText>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="row">*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormText name="dati_cedente_citta"  value={props.formik.dati_cedente_citta} onChange={props.formik.handleChange}*/}
                    {/*                          type="text" label="Città" placeholder="Inserisci qui"></FormText>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormText name="dati_cedente_indirizzo"  value={props.formik.dati_cedente_indirizzo} onChange={props.formik.handleChange}*/}
                    {/*                          type="text" label="Indirizzo" placeholder="Inserisci qui"></FormText>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="row">*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormText name="dati_cedente_telefono"  value={props.formik.dati_cedente_telefono} onChange={props.formik.handleChange}*/}
                    {/*                          type="number" label="Telefono" placeholder="Inserisci qui"></FormText>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormText name="dati_cedente_email"  value={props.formik.dati_cedente_email} onChange={props.formik.handleChange}*/}
                    {/*                          type="text" label="Email" placeholder="Inserisci qui"></FormText>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="row">*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormDatePicker  name="data_di_acquisto" value={props.formik.data_di_acquisto}*/}
                    {/*                                 label="Acquistato il" formik={props.formik}></FormDatePicker>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormUpload formik={props.formik} name="dati_cedente_carta_identita" value={props.formik.values.dati_cedente_carta_identita}*/}
                    {/*                            type="file" label="Documento Carta di identità (1)"></FormUpload>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="row">*/}
                    {/*            <div className="col-md-6 my-3">*/}
                    {/*                <FormUpload formik={props.formik} name="dati_cedente_documento_codicefiscale" value={props.formik.values.dati_cedente_documento_codicefiscale}*/}
                    {/*                            type="file" label="Documento Codice Fiscale (1)"></FormUpload>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </>}*/}

                    {/*</>}*/}

                    <div style={containerActionsStyles}>

                        <i className="fs-14">Tutti i campi sono obbligatori</i>

                        <hr/>
                        <div style={actionsStyles}>
                            <div>
                                <Button className="bg-white" variant="outline-primary" onClick={cancel}>Indietro</Button>
                            </div>
                            <div>
                                <Button variant="primary" type="submit" disabled={!props.formik.isValid || !props.formik.dirty}>Prossimo</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default OwnershipData;
