import React, {useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import {Button, Form, Spinner} from "react-bootstrap";
import FormSelect from "./FormSelect";
import { Formik } from "formik";
import FormText from "./FormText";
import FormUpload from "./FormUpload";
import {useGetComuniQuery, useGetPrestazioniQuery} from "../services/apiSlice";

interface Props {
    formik: any;
    modalShow: boolean;
    setModalShow(show: boolean): void;
    districtId: number;
    isLoading: boolean;
}

const ModalNewService = (props: Props) => {

    const { data: prestazioniData, error: prestazioniError } = useGetPrestazioniQuery(true);

    const {
        data: comuniData,
        error: errorComuni,
        refetch: refetchComuniData} = useGetComuniQuery(props.districtId, {
        skip: !props.districtId
    });

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    useEffect(() => {
        refetchComuniData();
    }, [props.districtId]);

    // @ts-ignore
    return (
        <Modal
            size="lg"
            centered
            show={props.modalShow}
            onHide={() => props.setModalShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header className="bg-primary">
                <Modal.Title id="example-custom-modal-styling-title">
                    <h2 className="text-white title-modal">
                        Inserimento prestazione manuale
                    </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Formik initialValues={props.formik.initialValues}  onSubmit={props.formik.handleSubmit}>
                    <Form onSubmit={props.formik.handleSubmit}>
                        <h2 className="my-3">
                            Prestazione
                        </h2>

                        <div className="row">
                            {prestazioniData && <div className="col-md-6 my-3">
                                <FormSelect name="tipo_prestazione" formik={props.formik}
                                            label="Tipologia Prestazione"
                                            value={props.formik.values.tipo_prestazione}
                                            options={prestazioniData}></FormSelect>
                            </div>}
                            {comuniData && <div className="col-md-6 my-3">
                                <FormSelect name="comune" formik={props.formik} label="Comune"
                                            value={props.formik.values.comune}
                                            options={comuniData}></FormSelect>
                            </div>}
                        </div>

                        <div className="row pb-4">
                            <div className="col-md-6 my-3">
                                <FormText name="note" value={props.formik.note} onChange={props.formik.handleChange}
                                          type="text" label="Indirizzo Prestazione" placeholder="Inserisci qui"></FormText>
                            </div>
                        </div>

                        <h2 className="my-3">
                            Dati Utente
                        </h2>

                        <div className="row">
                            <div className="col-md-6 my-3">
                                <FormText name="nome"  value={props.formik.nome} onChange={props.formik.handleChange} error={props.formik.errors["nome"]}
                                          type="text" label="Nome" placeholder="Inserisci qui"></FormText>

                            </div>
                            <div className="col-md-6 my-3">
                                <FormText name="cognome" value={props.formik.cognome} onChange={props.formik.handleChange} error={props.formik.errors["cognome"]}
                                          type="text" label="Cognome" placeholder="Inserisci qui"></FormText>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-3">
                                <FormText name="codicefiscale"  value={props.formik.codicefiscale} onChange={props.formik.handleChange} error={props.formik.errors["codicefiscale"]}
                                          type="text" label="Codice Fiscale" placeholder="Inserisci qui"></FormText>
                            </div>
                            <div className="col-md-6 my-3">
                                <FormText name="indirizzo"  value={props.formik.indirizzo} onChange={props.formik.handleChange} error={props.formik.errors["indirizzo"]}
                                          type="text" label="Indirizzo" placeholder="Inserisci qui"></FormText>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-3">
                                <FormText name="citta"  value={props.formik.citta} onChange={props.formik.handleChange} error={props.formik.errors["citta"]}
                                          type="text" label="Città" placeholder="Inserisci qui"></FormText>
                            </div>
                            <div className="col-md-6 my-3">
                                <FormText name="cap"  value={props.formik.cap} onChange={props.formik.handleChange} error={props.formik.errors["cap"]}
                                          type="number" label="CAP" placeholder="Inserisci qui"></FormText>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-3">
                                <FormText name="provincia"  value={props.formik.provincia} onChange={props.formik.handleChange} error={props.formik.errors["provincia"]}
                                          type="text" label="Provincia" placeholder="Inserisci qui"></FormText>
                            </div>
                            <div className="col-md-6 my-3">
                                <FormText name="telefono"  value={props.formik.telefono} onChange={props.formik.handleChange} error={props.formik.errors["telefono"]}
                                          type="number" label="Telefono" placeholder="Inserisci qui"></FormText>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-3">
                                <FormText name="email"  value={props.formik.email} onChange={props.formik.handleChange} error={props.formik.errors["email"]}
                                          type="text" label="Email" placeholder="Inserisci qui"></FormText>
                            </div>
                        </div>

                        <div style={containerActionsStyles}>
                            <hr/>
                            <div style={actionsStyles}>
                                <div>
                                    <Button className="bg-white" variant="outline-primary" onClick={() => props.setModalShow(false)}>Indietro</Button>
                                </div>
                                <div>
                                    <Button variant="primary" type="submit">
                                        {props.isLoading && <span className="me-2">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            variant="light"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                    </span>}
                                        Crea Prestazione
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>

            </Modal.Body>
        </Modal>
    );
}

export default ModalNewService;
