import React from "react";
import Modal from "react-bootstrap/Modal";
import {Book} from "../assets/interface/interface";
import {Button} from "react-bootstrap";
import InfoSportello from "./InfoSportello";

interface Props {
    book: Book;
    modalShow: boolean;
    setModalShow(show: boolean): void;
    createBook(): void;
}

const ModalConfirm = (props: Props) => {

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    // @ts-ignore
    return (
        <Modal
            size="lg"
            centered
            show={props.modalShow}
            onHide={() => props.setModalShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header className="bg-primary">
                <Modal.Title id="example-custom-modal-styling-title" >
                    <h2 className="text-white title-modal">
                        Conferma Prenotazione
                    </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="pt-4">
                    Conferma Prenotazione per: <b className="text-black">{props.book?.tipo_prenotazione?.label}</b>

                    <div className="mt-3">
                        <InfoSportello book={props.book}></InfoSportello>
                    </div>


                    <div style={containerActionsStyles}>
                        <hr/>
                        <div style={actionsStyles}>
                            <div>
                                <Button className="bg-white" variant="outline-primary" onClick={() => props.setModalShow(false)}>Indietro</Button>
                            </div>
                            <div>
                                <Button variant="primary" onClick={props.createBook}>Prenota</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default ModalConfirm;
