import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../config';
import {Logs, Place, Prestazione, Service} from '../assets/interface/interface'

interface SessionData {
    user_id: number;
    logged: boolean;
    role: "admin" | "veterinario";
    district_id: number;
}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
    }),
    endpoints: (builder) => ({
        getSession: builder.query<SessionData, void>({
            query: () => {
                return { url: `/front/get_session.php`, credentials: "include" };
            },
        }),
        getStrutture: builder.query<Place[], void>({
            query: () => '/front/get_struttura.php',
        }),
        getComuni: builder.query<Place[], any>({
            query: (district_id?: number) =>
                `/front/get_comuni.php${district_id ? `?district_id=${district_id}` : ''}`,
        }),
        getPrestazioni: builder.query<Prestazione[], boolean>({
            query: (animali_da_reddito: boolean) => `/front/get_prestazioni.php?reddito=${animali_da_reddito}`,
        }),
        getEventsToAssign: builder.query<Service[], any>({
            query: (district_id: any) => `/front/get_events_to_assign.php?district_id=${district_id}`,
        }),
        getDistrictCalendar: builder.query<any[], { district_id: any, slot_duration: number, end_date: string }>({
            query: ({ district_id, slot_duration, end_date }) =>
                `/front/get_district_calendar.php?district_id=${district_id}&slot_duration=${slot_duration}&end_date=${end_date}`,
        }),
        getNearestVeterinary: builder.query<any[], { district_id: any, start_time: string, end_time: string, booking_id: number }>({
            query: ({ district_id, start_time, end_time, booking_id }) =>
                `/front/get_nearest_veterinary.php?district_id=${district_id}&start_time=${start_time}&end_time=${end_time}&booking_id=${booking_id}`,
        }),
        getLogs: builder.query<Logs[], any>({
            query: (district_id: any) => `/front/get_logs.php?district_id=${district_id}`,
        }),
        createManualService: builder.mutation({
            query: (newPost) => ({
                url: '/front/insert_manual_booking.php',
                method: 'POST',
                body: newPost,
            }),
        }),
        createDistrictService: builder.mutation({
            query: (newPost) => ({
                url: '/front/insert_district_booking.php',
                method: 'POST',
                body: newPost,
            }),
        }),
        createAssignBooking: builder.mutation({
            query: (newPost) => ({
                url: '/front/assign_booking.php',
                method: 'POST',
                body: newPost,
            }),
        }),
    }),
});

export const {
    useGetSessionQuery,
    useGetStruttureQuery,
    useGetComuniQuery,
    useGetPrestazioniQuery,
    useGetEventsToAssignQuery,
    useGetDistrictCalendarQuery,
    useGetNearestVeterinaryQuery,
    useGetLogsQuery,
    useCreateManualServiceMutation,
    useCreateDistrictServiceMutation,
    useCreateAssignBookingMutation
} = apiSlice;
