import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faClock} from "@fortawesome/free-regular-svg-icons";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {Book} from "../assets/interface/interface";
import {addMinutes, format} from "date-fns";
import axios from "axios";
import {config} from "../config";
import {ToastContext} from "../services/ToastService";
interface Props {
    book: Book;
}
const InfoSportello = (props: Props) => {
    const [slot, setSlot] = useState<number>();
    const {showToast} = useContext(ToastContext)!;
    async function getLaboratorio() {
        if (!props.book.sportello) {
            return;
        }
        try {
            const response = await axios.get(`${config.apiUrl}/front/get_laboratorio.php`, {
                params: {
                    id: props.book.sportello.value
                }
            });

            setSlot(parseInt(response.data[0].slot));

        } catch (error) {
            // Gestisci eventuali errori qui
            showToast("danger", "Errore", "Errore durante la richiesta del laboratorio.")
            console.error('Errore durante la richiesta API:', error);
        }
    }

    useEffect(() => {
        getLaboratorio();
    }, [])

    return (
        <div className="mt-2">
            <div className="d-flex flex-row gap-3 align-items-center">
                <div className="fs-3 text-primary">
                    <FontAwesomeIcon icon={faLocationDot}/>
                </div>
                <div>
                    <div className="fw-bold text-uppercase text-black">
                        {props.book.tipologia_animali === "animali_da_affezione"
                            ? props.book?.sportello.label
                            : props.book?.comune.label}
                    </div>

                </div>
            </div>
            {props.book.date_prenotazione && <div className="d-flex flex-row gap-3 align-items-center my-2">
                <div className="fs-3 text-primary">
                    <FontAwesomeIcon icon={faCalendar}/>
                </div>
                <div className="fw-bold text-uppercase text-black">
                    {props.book.date_prenotazione && format(new Date(props.book.date_prenotazione), 'dd/MM/yyyy')}
                </div>
            </div>}

            {props.book.date_prenotazione && <div className="d-flex flex-row gap-3 align-items-center">
                <div className="fs-3 text-primary">
                    <FontAwesomeIcon icon={faClock}/>
                </div>

                <div className="fw-bold text-uppercase text-black">
                    {props.book.date_prenotazione && new Date(props.book.date_prenotazione).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    })}
                    - {props.book?.date_prenotazione && addMinutes(props.book.date_prenotazione, (slot ? slot : 60)).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                })}
                </div>
            </div>}
        </div>
    );
};

export default InfoSportello;
