import React from 'react';

const Contacts = () => {

        return (
            <div className="container">
                <h1>Sportelli</h1>

                <div className="row">
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Varese</b>
                        <br/><br/>
                        <a href="mailto:veterinarivarese@ats-insubria.it">veterinarivarese@ats-insubria.it</a>
                        <p>
                            Via Osoppo 12 - 21100 Varese (Va)
                            <br/>
                            Tel. <a href="tel:+390332333681">0332/333681</a> -
                            <a href="tel:+390332240560">0332/240560</a>
                        </p>
                    </div>
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Laveno</b>
                        <br/><br/>
                        <a href="mailto:veterinarilaveno@ats-insubria.it">veterinarilaveno@ats-insubria.it</a>
                        <p>
                            Via Ceretti 8 - 21014 Laveno Mombello (Va)
                            <br/>
                            Tel. <a href="tel:+390332625349">0332/625349</a> -
                            <a href="tel:+390332625350">0332/625350</a>
                        </p>
                    </div>
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Busto Arsizio</b>
                        <br/><br/>
                        <a href="mailto:veterinaribusto@ats-insubria.it">veterinaribusto@ats-insubria.it</a>
                        <p>
                            P.zza Plebiscito 1 – 21052 Busto Arsizio
                            <br/>
                            Tel. <a href="tel:+390331621298">0331/621298</a> -
                            <a href="tel:+390331624242">0331/624242</a>
                        </p>
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Gallarate</b>
                        <br/><br/>
                        <a href="mailto:veterinarigallarate@ats-insubria.it">veterinarigallarate@ats-insubria.it</a>
                        <p>
                            Corso L. Da Vinci 1–  21013 Gallarate (VA)
                            <br/>
                            Tel. <a href="tel:+390332277963">0332/277963</a> e <a href="tel:+390332277964">0332/277964</a>


                        </p>
                    </div>
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Como</b>
                        <br/><br/>
                        <a href="mailto:veterinaria.como@ats-insubria.it">
                            veterinaria.como@ats-insubria.it</a>
                        <p>
                            Via Castelnuovo 1 - 22100 Como (CO)
                            <br/>
                            Tel. <a href="tel:+39031370300">031/370300</a>
                        </p>
                    </div>
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Cantù</b>
                        <br/><br/>
                        <a href="mailto:veterinaria.comosud@ats-insubria.it">
                            veterinaria.comosud@ats-insubria.it</a>
                        <p>
                            Via Ospedale 18 - 22063 CANTU' (Co)
                            <br/>
                            Tel. <a href="tel:+39031713430">031/713430</a>
                        </p>
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Erba</b>
                        <br/><br/>
                        <a href="mailto:veterinaria.erba@ats-insubria.it">
                            veterinaria.erba@ats-insubria.it</a>
                        <p>
                            Via M. D'Azeglio 5 - 22036 ERBA (Co)
                            <br/>
                            Tel. <a href="tel:+39031370375">031/370375</a>
                        </p>
                    </div>
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Olgiate Comasco</b>
                        <br/><br/>
                        <a href="mailto:veterinaria.comosud@ats-insubria.it">
                            veterinaria.comosud@ats-insubria.it</a>
                        <p>
                            Via Roma 61 - 22077 Olgiate Comasco (Co)
                            <br/>
                            Tel.<a href="tel:+39031999120">031/999120</a>
                        </p>
                    </div>
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Porlezza</b>
                        <br/><br/>
                        <a href="mailto:veterinaria.porlezza@ats-insubria.it">
                            veterinaria.comosud@ats-insubria.it</a>
                        <p>
                            Via Garibaldi 62 - 22018 Porlezza (Co)
                            <br/>
                            Tel.<a href="tel:+39034462828">0344/62828</a>
                        </p>
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Tremezzina</b>
                        <br/><br/>
                        {/*<a href="mailto:veterinaria.erba@ats-insubria.it">*/}
                        {/*    veterinaria.erba@ats-insubria.it</a>*/}
                        <p>
                            Via Ugo Ricci 2 - 22016 tremezzina (Co)
                            <br/>
                            Tel. <a href="tel:+39034462828">0344/62828</a>
                        </p>
                    </div>
                    <div className="col-md-4 col-xs-12 my-3">
                        <b className="text-black fs-5">Sportello Alta Valle Intelvi</b>
                        <br/><br/>
                        {/*<a href="mailto:veterinaria.comosud@ats-insubria.it">*/}
                        {/*    veterinaria.comosud@ats-insubria.it</a>*/}
                        <p>
                            Via Lanfranconi 34 - 22020 Pellio Intelvi
                            <br/>
                            Tel.<a href="tel:+39034462828">0344/62828</a>
                        </p>
                    </div>
                </div>

            </div>
        );
};

export default Contacts;
