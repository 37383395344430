import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface SliderComponentProps {
    slideShow: number;
    children: React.ReactNode;
}

const SliderComponent: React.FC<SliderComponentProps> = (props) => {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: props.slideShow,
        slidesToScroll: props.slideShow,
        initialSlide: 0,
        adaptiveHeight: false,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: props.slideShow,
                    slidesToScroll: props.slideShow,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="slider-container">
            <Slider
                {...settings}
            >
                {props.children}
            </Slider>
        </div>
    );
};

export default SliderComponent;
